import NiceModal from "@ebay/nice-modal-react";
import moment from 'moment';
import snakecaseKeys from "snakecase-keys";
import { Space, Spin, Typography, Tooltip, Button } from "antd";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DepositTransactionModalForm, EditDropdown, TransactionViewMore, toast, DateTime, AdminBankAccount, CustomerBankAccount, ColumnType, Amount, BannerType } from "src/components";
import TransactionResultModal from "src/components/Modals/TransactionResultModal/TransactionResultModal";
import colors from 'src/theme/colors.json';
import { AmountColumn } from "src/components/Table";
import { useDeleteTransaction } from "src/hooks";
import { useDepositBankAccounts } from "src/hooks/useDepositBankAccount";
import { GroupBank, Scope } from "src/types";
import Icon from "../../components/Icon/Icon";
import { Menu, MenuItem } from "../../components/Menu/Menu";
import { Option } from "../../components/RadioButton/RadioButton";
import { OurTableProps } from "../../components/Table/Table";
import { isDepositTransaction, isWithdrawTransaction, mapToTransaction } from "../../mapper/mapToTransaction";
import { affiliateStateAtom, authInfoState, authState, loadingState, modalState } from "../../recoils";
import { requestChangeTransactionStatus } from "../../services/changeTransactionStatus";
import { cancelReserveDepositTransaction, reserveDepositTransaction } from "../../services/payment";
import { cancelReserveWithdrawTransaction, PostRetryAutoWithdraw, ReserveTransactionResponse, reserveWithdrawTransaction, RetryAutoWithdrawResponse } from "../../services/payment/payment";
import { BaseResponse } from "../../services/service.type";
import { DEPOSIT_TRANSACTION_STATUS, PutManualWithdraw, WITHDRAW_TRANSACTION_STATUS } from "../../services/transactions/transactions";
import { formatDate, formatTime } from "../../utils/date";
import { decodeAuthToken } from "../../utils/decode/decode";
import {
  DEPOSIT_FILTER_HISTORY_TRANSACTIONS,
  DEPOSIT_FILTER_PENDING_TRANSACTIONS,
  getCallConfig,
  SELECTED_TAP,
  WITHDRAW_FILTER_HISTORY_TRANSACTIONS,
  WITHDRAW_FILTER_PENDING_TRANSACTIONS,
  WITHDRAW_PROCESSING_TRANSACTIONS
} from "./config";
import { ActionColumn, TransactionStatus, CancelReserve, Remark } from "./renderColumn";
import { DEPOSIT_STATUS, FILTER, SearchCriteria, SearchResult, SearchValue, Transaction, UserInfo, WITHDRAW_STATUS } from "./type";
import { getColumnFilters } from "src/utils/table";
import BonusColumn from "src/components/TableColumns/BonusTypeColumn";
import Label from "src/components/Text/Label";

export type AllColumnField = 'createdAt' | 'transferedAt' | 'transferedAtDate' | 'transferedAtTime' | 'adminAccount' | 'customerAccount' | 'amount' | 'isBonus' | 'remark' | 'status' | 'moreDetail' | 'cancelReserve' | 'actions' | 'userAccount' | 'updatedAt' | 'bonus';

export type TransactionState = {
  transactions: Transaction[];
  isTransactionsLoading: boolean;
  getSelectedTab: () => FILTER;
  getFilterOptions: () => Option[];
  getHistoryDepositTransactionColumn: () => any[];
  getBaseTableProps: () => OurTableProps;
  depositingTransaction?: Transaction;
  withdrawingTransaction?: Transaction;
  refundRequestingTransaction?: Transaction;
  refundingTransaction?: Transaction;
  cancelingRefundTransaction?: Transaction;
  editingManualWithdraw?: Transaction;
  deletingManualWithdraw?: Transaction;
  editingManualDeposit?: Transaction;
  deletingManualDeposit?: Transaction;
  adjustingTransaction?: Transaction;
  depositingUser?: UserInfo;
  setDepositingTransaction: (_t?: Transaction) => void;
  searchCriteria: SearchCriteria;
  searchResult: SearchResult;
  setSearchCriteria: (_s: SearchCriteria) => void;
  searchValue: SearchValue
  setSearchValue: (_s: SearchValue) => void;
  doCallDepositTransactions: (_s: SearchCriteria) => void;
  doCallWithdrawTransaction: (_s: SearchCriteria) => void;
  reserveTransaction: (_transaction: Transaction, type?: ReserveType) => void;
  cancelReserveTransaction: (_transaction: Transaction, type?: ReserveType, is_closeModal?: boolean) => Promise<ReserveTransactionResponse>;
  reserveUser: (_u: UserInfo) => void;
  cancelReserveUser: (_u: UserInfo) => void;
  requestCancelRefundDepositTransaction: (_transaction: Transaction) => Promise<BaseResponse | null>;
  setTransactions: (_transaction: Transaction[]) => void;
  setSearchResult: (_s: SearchResult) => void;
  getAllColumn: () => Record<AllColumnField, ColumnType<Transaction>>;
  shouldCallAdjustDeposit: boolean;
  setShouldCallAdjustDeposit: (_b: boolean) => void;
  setRetryAutoWithdrawingTransaction: (_t: Transaction) => void;
  doCallRetryAutoWithdraw: (_t: Transaction, groupBank: GroupBank) => void;
  showDoneTransactionModal: (_t: Transaction) => void;
  step: AdjustTransactionState;
  setStep: (_t: AdjustTransactionState) => void;
};

export type IntialUseTransferState = {
  filter: FILTER;
  websocket?: any | null;
};

export type ReserveType = DEPOSIT_STATUS | WITHDRAW_STATUS | 'EDIT_DEPOSIT' | 'DELETE_DEPOSIT' | 'EDIT_WITHDRAW' | 'DELETE_WITHDRAW';

export enum AdjustTransactionStep {
  AdjustUserSuccess = 'AdjustUserSuccess',
  AdjustUserError = 'AdjustUserError',
  AdjustSlipSuccess = 'AdjustSlipSuccess',
  AdjustSlipError = 'AdjustSlipError',
  NONE = 'NONE',
}

export type AdjustTransactionState = {
  at: AdjustTransactionStep;
  isLoading: boolean;
  payload: {
    newTransaction?: Transaction;
    newUser?: UserInfo;
  };
}

export const initialResult: AdjustTransactionState = {
  at: AdjustTransactionStep.NONE,
  isLoading: false,
  payload: {},
};

export const RESULT_CONFIG = {
  [AdjustTransactionStep.AdjustUserSuccess]: {
    type: BannerType.Success,
    text: 'ตัดเงินและโบนัสสำเร็จ',
    descriptionBackground: colors['@yellow-4'],
    description: (_transaction: Transaction) => (<>
      <div>รายการถูกแก้ไขผิดยูส และระบบเพิ่มรายการเติมเงินใหม่เรียบร้อยแล้ว</div>
      <div style={{ fontSize: 14, fontWeight: 400 }} >ต้องการดำเนินการเติมเงินให้กับรายการนี้หรือไม่ ?</div>
    </>),
  },
  [AdjustTransactionStep.AdjustUserError]: {
    type: BannerType.Error,
    text: 'ตัดเงินและโบนัสไม่สำเร็จ',
    descriptionBackground: colors['@yellow-4'],
    description: (_transaction: Transaction) => (<>
      <div>รายการถูกแก้ไขผิดยูส และระบบเพิ่มรายการเติมเงินใหม่เรียบร้อยแล้ว</div>
      <div style={{ fontSize: 14, fontWeight: 400 }} >ต้องการดำเนินการเติมเงินต่อให้กับรายการที่ถูกเติมผิดไปหรือไม่ ?</div>
    </>),
  },
  [AdjustTransactionStep.AdjustSlipSuccess]: {
    type: BannerType.Success,
    text: 'ตัดเงินและโบนัสสำเร็จ',
    descriptionBackground: colors['@volcano-4'],
    description: (_transaction: Transaction) => (<>
      <div>รายการถูกแก้ไขเป็นสถานะรอเติมเงินเรียบร้อยแล้ว</div>
      <div style={{ fontSize: 14, fontWeight: 400 }} >ต้องการดำเนินการต่อ เพื่อเลือกรายการเติมเงินที่ถูกต้องให้กับลูกค้า {_transaction.userAccount} หรือไม่ ?</div>
    </>),
  },
  [AdjustTransactionStep.AdjustSlipError]: {
    type: BannerType.Error,
    text: 'ตัดเงินและโบนัสไม่สำเร็จ',
    descriptionBackground: colors['@volcano-4'],
    description: (_transaction: Transaction) => (<>
      <div>รายการถูกแก้ไขผิดสลิป และระบบเพิ่มรายการเติมเงินใหม่เรียบร้อยแล้ว</div>
      <div style={{ fontSize: 14, fontWeight: 400 }} >กรุณากด “ดำเนินการต่อ” และเลือกรายการที่ถูกต้อง เพื่อยกเลิกหรือเติมเงินเพิ่มให้กับลูกค้า</div>
    </>),
  },
};


export const useTransactionState = (initialState: IntialUseTransferState): TransactionState => {
  const [, setModal] = useRecoilState(modalState);
  const [, setLoading] = useRecoilState(loadingState);
  const [affiliateState] = useRecoilState(affiliateStateAtom);

  const [reserveType, setReserveType] = React.useState<ReserveType | undefined>(undefined);
  const [depositingTransaction, setDepositingTransaction] = React.useState<Transaction>();
  const [withdrawingTransaction, setWithdrawingTransaction] = React.useState<Transaction>();
  const [retryAutoWithdrawTransaction, setRetryAutoWithdrawingTransaction] = React.useState<Transaction>();
  const [refundRequestingTransaction, setRefundRequestingTransaction] = React.useState<Transaction>();
  const [refundingTransaction, setRefundingTransaction] = React.useState<Transaction>();
  const [cancelingRefundTransaction, setCancelingRefundTransaction] = React.useState<Transaction>();
  const [editingManualWithdraw, setEditingManualWithdraw] = React.useState<Transaction>();
  const [deletingManualWithdraw, setDeletingManualWithdraw] = React.useState<Transaction>();
  const [editingManualDeposit, setEditingManualDeposit] = React.useState<Transaction>();
  const [deletingManualDeposit, setDeletingManualDeposit] = React.useState<Transaction>();
  const [adjustingTransaction, setAdjustingTransaction] = React.useState<Transaction>();
  const [depositingUser, setDepositingUser] = React.useState<UserInfo>();
  const [shouldCallAdjustDeposit, setShouldCallAdjustDeposit] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<AdjustTransactionState>(initialResult)
  const { token, scope, userId } = useRecoilValue(authInfoState);
  const [isTransactionsLoading, setIsTransactionLoading] = React.useState<boolean>(true);
  const [transactions, setTransactions] = React.useState<Transaction[]>([]);
  const [searchCriteria, setSearchCriteria] = React.useState<SearchCriteria>({
    limit: initialState.filter === FILTER.PENDING_DEPOSIT ? 200 : 100,
    offset: 1,
    filter: initialState.filter,
    transferredAt: undefined,
  });

  const [searchResult, setSearchResult] = React.useState<SearchResult>({
    ...searchCriteria,
    totalPage: 0,
    totalTransaction: transactions.length,
    totalOtherTransaction: 0,
  })
  const [searchValue, setSearchValue] = React.useState<SearchValue>({
    value: ""
  })

  const { mutate: deleteTransaction } = useDeleteTransaction()

  React.useEffect(() => {
    // TO CONSIDER : might not use this when we move logic from reserverTransaction() to useEffect
    const authInfo = decodeAuthToken(token)
    const areYouDoingTransaction = withdrawingTransaction || retryAutoWithdrawTransaction
      || refundRequestingTransaction || refundingTransaction || cancelingRefundTransaction
      || editingManualWithdraw || deletingManualWithdraw
      || editingManualDeposit || deletingManualDeposit;
    if (areYouDoingTransaction) return;
    const myEditingTransaction = transactions.find(_transaction => _transaction.editingBy === authInfo.user_uid);
    if (myEditingTransaction?.status === DEPOSIT_STATUS.PENDING || myEditingTransaction?.status === WITHDRAW_STATUS.PENDING) {
      return;
    }
    const updateMyEditingTransaction = async (_transaction: Transaction) => {
      cancelReserveTransaction(_transaction);
    };

    if (myEditingTransaction) updateMyEditingTransaction(myEditingTransaction);
  }, [transactions])

  React.useEffect(() => {
    const authInfo = decodeAuthToken(token)
    const mySelectingTransaction = transactions.find(_transaction => _transaction.editingBy === authInfo.user_uid);
    // NOTE : Revert back realtime transaction
    // if (reserveType === DEPOSIT_STATUS.PENDING) {
    //   if (mySelectingTransaction?.status === DEPOSIT_STATUS.PENDING && depositingTransaction === undefined) {
    //     setDepositingTransaction(mySelectingTransaction);
    //     return;
    //   }

    // }
    // if (reserveType === 'EDIT_DEPOSIT') {
    //   if (mySelectingTransaction?.status === DEPOSIT_STATUS.PENDING && depositingTransaction === undefined) {
    //     setEditingManualDeposit(mySelectingTransaction);
    //     NiceModal.show(DepositTransactionModalForm, {
    //       transaction: mySelectingTransaction,
    //       onClose: () => cancelReserveTransaction(mySelectingTransaction, 'EDIT_DEPOSIT'),
    //       onSuccess: () => doCallDepositTransactions(searchCriteria),
    //     });
    //     return;
    //   }
    // }
    // if (reserveType === 'DELETE_DEPOSIT') {
    //   if (mySelectingTransaction?.status === DEPOSIT_STATUS.PENDING && depositingTransaction === undefined) {
    //     setDeletingManualDeposit(mySelectingTransaction);
    //     setModal({
    //       isModalVisible: true,
    //       type: 'warning',
    //       title: 'ลบรายการ',
    //       content: 'ต้องการลบรายการเติมเงินนี้ใช่หรือไม่',
    //       buttonType: 'question',
    //       onConfirm: () =>
    //         deleteTransaction(mySelectingTransaction.transactionId, {
    //           onSettled: () => cancelReserveTransaction(mySelectingTransaction, 'DELETE_DEPOSIT'),
    //           onSuccess: () => doCallDepositTransactions(searchCriteria),
    //         }),
    //       onCancel: () => cancelReserveTransaction(mySelectingTransaction)
    //     })
    //   }
    // }

    // NOTE : when we left transaction, we cancel it
    if (reserveType === undefined && mySelectingTransaction) { cancelReserveTransaction(mySelectingTransaction) }
  }, [transactions, reserveType])

  const getSelectedTab = (): FILTER => {
    return SELECTED_TAP[searchCriteria.filter];
  };

  const getFilterOptions = (): Option[] => {
    let options = DEPOSIT_FILTER_PENDING_TRANSACTIONS;
    switch (searchCriteria.filter) {
      case FILTER.PENDING_DEPOSIT:
      case FILTER.PENDING_REFUND:
        options = DEPOSIT_FILTER_PENDING_TRANSACTIONS
        break;
      case FILTER.ONLY_ME_DEPOSIT:
        options = DEPOSIT_FILTER_HISTORY_TRANSACTIONS;
        break;
      // case FILTER.ALL_DEPOSIT:
      //   options = DEPOSIT_FILTER_HISTORY_TRANSACTIONS.filter(o => ((o.role && scope[o.role]?.enable) || (o.role === undefined)));
      //   break;
      case FILTER.PENDING_WITHDRAW:
      case FILTER.REFUND:
        options = affiliateState.isAffiliateMode
          ? WITHDRAW_FILTER_PENDING_TRANSACTIONS.filter(o => o.value !== FILTER.REFUND)
          : WITHDRAW_FILTER_PENDING_TRANSACTIONS;
        break;
      case FILTER.WITHDRAW_PROCESSING:
        options = WITHDRAW_PROCESSING_TRANSACTIONS;
        break;
      case FILTER.ONLY_ME_WITHDRAW:
        options = WITHDRAW_FILTER_HISTORY_TRANSACTIONS;
        break;
      // case FILTER.ALL_WITHDRAW:
      //   options = WITHDRAW_FILTER_HISTORY_TRANSACTIONS.filter(o => ((o.role && scope[o.role]?.enable) || (o.role === undefined)));
      //   break;
      default:
        return [];
    }
    return options.map((option) => {
      if (option.value === searchCriteria.filter) {
        return {
          ...option,
          text: <>{option.text}&nbsp;{isTransactionsLoading ? <Spin size="small" /> : <>({searchResult.totalTransaction})</>}</>,
        }
      }
      return {
        ...option,
        text: <>{option.text}&nbsp;{isTransactionsLoading ? <Spin size="small" /> : <>({searchResult.totalOtherTransaction})</>}</>,
      };
    });

  };

  const showDoneTransactionModal = (_transaction: Transaction) => {
    const isDepositTransaciton = isDepositTransaction(_transaction);
    const isWithdrawTransaciton = isWithdrawTransaction(_transaction);
    setModal({
      isModalVisible: true,
      type: 'error',
      title: 'ไม่สามารถทำรายการได้',
      content: 'เนื่องจากรายการนี้มี Admin ท่านอื่นทำรายการอยู่',
      buttonType: 'confirm',
      onConfirm: () => {
        if (isDepositTransaciton) doCallDepositTransactions(searchCriteria);
        if (isWithdrawTransaciton) doCallWithdrawTransaction(searchCriteria);
      },
    });
  };

  const reserveTransaction = async (_transaction: Transaction, type?: ReserveType) => {
    const isDepositTransaciton = isDepositTransaction(_transaction);
    try {
      const reserveTransaction = isDepositTransaciton ? reserveDepositTransaction : reserveWithdrawTransaction;
      const response = await reserveTransaction({
        token,
        transaction_uid: _transaction.transactionId,
      });
      setReserveType(type || _transaction.status)
      console.log("response")
      console.log(response)
      if (response.status === 'SUCCESS') {
        console.log("reserveTransaction.")
        sendMessage()
        // NOTE : move to useEffect [transaction, reserveType]
        if (type === DEPOSIT_STATUS.PENDING) {
          setDepositingTransaction(_transaction);
        } else if (type === 'EDIT_DEPOSIT') {
          setEditingManualDeposit(_transaction);
          NiceModal.show(DepositTransactionModalForm, {
            transaction: _transaction,
            onClose: () => cancelReserveTransaction(_transaction, 'EDIT_DEPOSIT'),
            onSuccess: () => doCallDepositTransactions(searchCriteria),
          });
        } else if (type === 'DELETE_DEPOSIT') {
          setDeletingManualDeposit(_transaction);
          setModal({
            isModalVisible: true,
            type: 'warning',
            title: 'ลบรายการ',
            content: 'ต้องการลบรายการเติมเงินนี้ใช่หรือไม่',
            buttonType: 'question',
            onConfirm: () =>
              deleteTransaction(_transaction.transactionId, {
                onSettled: () => cancelReserveTransaction(_transaction, 'DELETE_DEPOSIT'),
                onSuccess: () => doCallDepositTransactions(searchCriteria),
              }),
            onCancel: () => cancelReserveTransaction(_transaction)
          })
        }
        else if (type === 'EDIT_WITHDRAW') {
          setEditingManualWithdraw(_transaction);
        } else if (type === 'DELETE_WITHDRAW') {
          setDeletingManualWithdraw(_transaction);
          setModal({
            isModalVisible: true,
            type: 'warning',
            title: 'ลบรายการ',
            content: 'ต้องการลบรายการถอนเงินนี้ใช่หรือไม่',
            buttonType: 'question',
            onConfirm: async () => {
              try {
                const response = await PutManualWithdraw({
                  token,
                  transaction_uid: _transaction.transactionId,
                  amount: _transaction.amount,
                  status: WITHDRAW_TRANSACTION_STATUS.DELETED,
                });
                if (response.service_code === 'BOE-2000') {
                  toast.success('ลบรายการถอนเงินสำเร็จ')
                } else {
                  toast.error('ลบรายการถอนเงินไม่สำเร็จ')
                }
                doCallWithdrawTransaction(searchCriteria);
              } catch {
                toast.error('ลบรายการถอนเงินไม่สำเร็จ')
                doCallWithdrawTransaction(searchCriteria);
              }
              // Call delete withdraw
            },
            onCancel: () => cancelReserveTransaction(_transaction)
          })
        } else if (type === WITHDRAW_STATUS.PENDING) {
          setWithdrawingTransaction(_transaction);
        } else if (type === DEPOSIT_STATUS.REJECTED) {
          setRefundRequestingTransaction(_transaction);
        } else if (type === DEPOSIT_STATUS.CANCEL_REJECT) {
          setCancelingRefundTransaction(_transaction);
        } else if (_transaction.status === DEPOSIT_STATUS.WAITING_TRANSFER) {
          setRefundingTransaction(_transaction);
        } else if (_transaction.status === DEPOSIT_STATUS.COMPLETED) {
          setAdjustingTransaction(_transaction);
        }
      }
    } catch (e) {
      showDoneTransactionModal(_transaction)
    }
  };

  // is_closeModal : flag for event close modal
  const cancelReserveTransaction = async (_transaction: Transaction, type?: ReserveType, is_closeModal?: boolean): Promise<ReserveTransactionResponse> => {
    const isDepositTransaciton = isDepositTransaction(_transaction);
    const cancelReserve = isDepositTransaciton ? cancelReserveDepositTransaction : cancelReserveWithdrawTransaction;
    const response = await cancelReserve({
      token,
      transaction_uid: _transaction.transactionId,
    });
    setReserveType(undefined);
    console.log("cancelReserveTransaction.")
    sendMessage()
    if (type === 'EDIT_DEPOSIT') setEditingManualDeposit(undefined);
    if (type === 'DELETE_DEPOSIT') setDeletingManualDeposit(undefined);
    if (type === 'EDIT_WITHDRAW') setEditingManualWithdraw(undefined);
    if (type === 'DELETE_WITHDRAW') setDeletingManualWithdraw(undefined);
    // if (type === DEPOSIT_STATUS.PENDING) setDepositingTransaction(undefined);
    if (type === WITHDRAW_STATUS.PENDING) setWithdrawingTransaction(undefined);
    if (type === DEPOSIT_STATUS.REJECTED) setRefundRequestingTransaction(undefined);
    if (type === DEPOSIT_STATUS.CANCEL_REJECT) setCancelingRefundTransaction(undefined);
    if (_transaction.status === DEPOSIT_STATUS.WAITING_TRANSFER) setRefundingTransaction(undefined);
    if (_transaction.status === DEPOSIT_STATUS.COMPLETED) setAdjustingTransaction(undefined);
    return response;
  };

  const reserveUser = (_user: UserInfo) => {
    setDepositingUser(_user);
  };

  const cancelReserveUser = (_user: UserInfo) => {
    setDepositingUser(undefined);
  };

  const requestCancelRefundDepositTransaction = async (_transaction: Transaction) => {
    const response = await requestChangeTransactionStatus({
      transaction_uid: _transaction.transactionId,
      transaction_status: DEPOSIT_TRANSACTION_STATUS.PENDING,
      token
    });

    return response;
  }

  const renderCancelReserve = (_transaction: Transaction, callTransactions: any) => {
    if (_transaction.editingBy !== '') {
      let createdAt = moment(_transaction?.editTransaction?.createdAt || _transaction.updatedAt);
      createdAt.add(7, 'hours');
      const now = moment();
      const diff = now.diff(createdAt, 'minutes');
      if (diff > 5) {
        return (
          <div style={{ textAlign: 'right' }}>
            <Space>
              <CancelReserve
                action={async () => {
                  await cancelReserveTransaction(_transaction);
                  await callTransactions(searchCriteria);
                }}
              />
            </Space>
          </div>
        )
      }
    }
  };

  const getAllColumn = (): Record<AllColumnField, ColumnType<Transaction>> => ({
    createdAt: {
      title: 'วันเวลาระบบลงรายการ',
      dataIndex: ['rawData', 'createdAt'],
      width: 150,
      render: (value: string) => {
        return <DateTime value={value} />
      },
    },
    transferedAt: {
      title: 'วันเวลาทำรายการ',
      dataIndex: 'transferAt',
      width: 150,
      render: (value: string) => {
        return <DateTime value={value} />
      },
    },
    transferedAtDate: {
      title: 'วันที่',
      dataIndex: 'transferAtDate',
      key: "transferAtDate",
      width: 111,
    },
    transferedAtTime: {
      title: 'เวลาสลิป',
      dataIndex: 'transferAtTime',
      key: 'transferAtTime',
      filterSearch: true,
      filters: getColumnFilters("transferAtTime", transactions || []),
      onFilter: (value, row) => row.transferAtTime == value.toString(),
      width: 100,
    },
    adminAccount: {
      title: 'บัญชี PIGSPIN',
      key: 'adminBank',
      ellipsis: true,
      width: 200,
      render: (_: any, record: Transaction) => {
        return (
          <AdminBankAccount transaction={record} />
        )
      },
    },
    customerAccount: {
      title: 'บัญชีลูกค้า',
      key: 'customerBank',
      dataIndex: 'customerBankNumber',
      ellipsis: true,
      filterSearch: true,
      filters: getColumnFilters("customerBankNumber", transactions || []),
      onFilter: (value, row) => row.customerBank.accountNumber == value,
      width: 200,
      render: (_: any, record: Transaction) => {
        return (
          <CustomerBankAccount transaction={record} />
        )
      },
    },
    amount: {
      title: 'จำนวนเงิน',
      dataIndex: 'amount',
      key: 'amount',
      filterSearch: true,
      filters: getColumnFilters("amount", transactions || []),
      onFilter: (value, row) => row.amount == value,
      width: 120,
      render: (value: number, transaction: Transaction) => {
        return <AmountColumn {...transaction} />
      },
    },
    isBonus: {
      title: <div style={{ textAlign: 'center' }}>
        <Tooltip placement="top" title="รับโบนัส">
          <Button type="text" style={{ padding: 0 }}>
            <Icon name="outline-tag" size="1rem" />
          </Button>
        </Tooltip>
      </div>,
      dataIndex: 'isBonus',
      key: 'isBonus',
      width: 50,
      align: "center",
      render: (isBonus, record) => {
        return <BonusColumn isBonus={record.isBonus} bonusType={record.bonusType} />
      },
    },
    remark: {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: 165,
      render: (_, record) => {
        return <Remark {...record} />
      }
    },
    status: {
      title: 'สถานะ',
      key: 'statusMessage',
      filterSearch: true,
      filters: getColumnFilters("statusMessage", transactions || []),
      onFilter: (value, row) => row.statusMessage == value.toString(),
      width: 140,
      render: (_: any, record: Transaction) => {
        return <TransactionStatus {...record} />
      }
    },
    moreDetail: {
      title: '',
      dataIndex: 'more',
      key: 'more',
      width: 50,
      render: (_: any, row: Transaction) => <TransactionViewMore transaction={row} />,
    },
    cancelReserve: {
      title: '',
      dataIndex: 'more',
      key: 'cancelReserve',
      width: 70,
      render: (_: any, row: Transaction) => renderCancelReserve(row, doCallDepositTransactions),
    },
    actions: {
      title: '',
      dataIndex: 'deposit',
      key: 'deposit',
      fixed: 'right',
      width: 130,
      render: (value: Date, row: Transaction) => {
        const menu = (
          <Menu>
            <MenuItem
              key="refund"
              style={{ padding: '1rem' }}
              onClick={() => {
                reserveTransaction(row, DEPOSIT_STATUS.REJECTED);
              }}
            >
              <Typography.Text style={{ fontSize: '1rem' }}  >
                <Icon name="outline-notify-1" size="1.5rem" />
                &nbsp;
                แจ้งคืนเงิน
              </Typography.Text>
            </MenuItem>
            <MenuItem
              key="cancelrefund"
              style={{ padding: '1rem' }}
              onClick={() => {
                reserveTransaction(row, DEPOSIT_STATUS.CANCEL_REJECT);
              }}
            >
              <Typography.Text style={{ fontSize: '1rem' }} >
                <Icon name="outline-close-2" size="1.5rem" />
                &nbsp;
                ยกเลิกแจ้งคืนเงิน
              </Typography.Text>
            </MenuItem>
          </Menu>
        );
        return (
          <div style={{ textAlign: 'right' }}>
            <Space>
              {(scope[Scope.EditDepositTransaction]?.enable || scope[Scope.DeleteDepositTransaction]?.enable)
                && row.status === DEPOSIT_STATUS.PENDING && (
                  <EditDropdown
                    disabled={!!row.editingBy}
                    showEdit={scope[Scope.EditDepositTransaction]?.enable && row.matchingState === "MANUAL"}
                    showDelete={scope[Scope.DeleteDepositTransaction]?.enable}
                    onEdit={() => reserveTransaction(row, 'EDIT_DEPOSIT')}
                    onDelete={() => reserveTransaction(row, 'DELETE_DEPOSIT')}
                  />
                )}
              <ActionColumn
                {...row}
                action={() => {
                  if (row.status !== DEPOSIT_STATUS.REJECTED) {
                    reserveTransaction(row, DEPOSIT_STATUS.PENDING)
                  }
                }}
                dropdownChildren={row.status === DEPOSIT_STATUS.REJECTED ? menu : undefined}
              />
            </Space>
          </div>
        )
      },
    },
    userAccount: {
      title: 'รหัส',
      dataIndex: 'userAccount',
      key: 'userAccount',
      width: 117,
      render: (value: string) => {
        return (
          <span>{value}</span>
        )
      },
    },
    updatedAt: {
      title: 'วันเวลาอัพเดทล่าสุด',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      render: (value: string) => {
        return <DateTime value={value} />
      },
    },
    bonus: {
      title: "โบนัส",
      key: "bonus",
      width: 120,
      render: (value, record) => {
        return (record.isBonus && record.bonus === 0) ? <Label>(รับโบนัสเกินกำหนดต่อวัน)</Label> : <Amount value={record.bonus} />
      },
    },
  })

  const getHistoryDepositTransactionColumn = () => {
    const allColumn = getAllColumn();

    return [
      allColumn.updatedAt,
      allColumn.transferedAt,
      allColumn.userAccount,
      allColumn.adminAccount,
      allColumn.customerAccount,
      allColumn.amount,
      allColumn.isBonus,
      allColumn.remark,
      allColumn.status,
      allColumn.moreDetail,
    ];
  };

  const getBaseTableProps = () => {
    return {
      isLoading: isTransactionsLoading,
      isEmpty: searchResult.totalTransaction === 0 && searchResult.totalOtherTransaction === 0,
      isSearchNotFound: searchResult.totalTransaction === 0 && searchResult.totalOtherTransaction > 0,
      dataSource: transactions,
      totalItem: searchResult.totalTransaction,
      limit: searchResult.limit,
      offset: searchResult.offset,
      onPaginationChange: (offset: number, limit: number) => {
        setSearchCriteria({
          ...searchCriteria,
          offset,
          limit,
        })
      }
    }
  }

  const doCallDepositTransactions = async (_searchCriteria: SearchCriteria) => {
    setIsTransactionLoading(true);
    const { directCall, otherTabCall } = getCallConfig(token, _searchCriteria, affiliateState);
    const promise1 = directCall.call(directCall.payload);
    const promise2 = otherTabCall.call(otherTabCall.payload);
    const [res, otherRes] = await Promise.all([promise1, promise2]);
    const response = res?.data;
    const otherResponse = otherRes?.data;

    if (response && response?.status === 'SUCCESS') {
      const { data } = response;
      setTransactions(response?.data.results.map((row) => mapToTransaction(row)) || [])
      setSearchResult({
        ...searchResult,
        ..._searchCriteria,
        limit: data.pageSize,
        offset: data.page,
        totalPage: data.total / data.pageSize,
        totalOtherTransaction: otherResponse ? otherResponse.data.total : 0,
        totalTransaction: data.total,
      })
      console.log("doCallDepositTransactions.")
      sendMessage()
    } else {
      setTransactions([])
      setSearchResult({
        ...searchResult,
        totalPage: 0,
        totalTransaction: 0,
        totalOtherTransaction: otherResponse ? otherResponse.data.total : 0,
      });
    }
    setIsTransactionLoading(false);
  };

  const doCallWithdrawTransaction = async (_searchCriteria: SearchCriteria) => {
    setIsTransactionLoading(true);
    const { directCall, otherTabCall } = getCallConfig(token, _searchCriteria, affiliateState);
    const promise1 = directCall.call(directCall.payload);
    const promise2 = otherTabCall.call(otherTabCall.payload);
    const [res, otherRes] = await Promise.all([promise1, promise2]);
    const response = res?.data;
    const otherResponse = otherRes?.data;
    const totalOtherTransaction = otherResponse ? otherResponse.data.total : 0;

    if (response?.status === 'SUCCESS') {
      const { data } = response;
      setTransactions(response?.data.results.map((row) => mapToTransaction(row)) || [])
      setSearchResult({
        ...searchResult,
        ..._searchCriteria,
        limit: data.pageSize,
        offset: data.page,
        totalPage: data.total / data.pageSize,
        totalOtherTransaction,
        totalTransaction: data.total,
      })
      console.log("doCallWithdrawTransaction.")
      sendMessage()
    } else {
      setTransactions([])
      setSearchResult({
        ...searchResult,
        totalPage: 0,
        totalTransaction: 0,
        totalOtherTransaction,
      });
    };
    setIsTransactionLoading(false);
  }

  const doCallRetryAutoWithdraw = async (_transaciton: Transaction, groupBank: GroupBank) => {
    setLoading({ isLoading: true })
    const pushToManualFlow = () => {

      setModal({
        isModalVisible: true,
        type: 'error',
        title: 'ไม่สามารถโอนเงินด้วยระบบอัตโนมัติได้',
        content: (
          <>
            <div>ต้องการโอนเงินผ่านแอปธนาคารใช่หรือไม่</div>
          </>
        ),
        buttonType: 'question',
        confirmText: 'ใช่ ต้องการ',
        onConfirm: () => {
          reserveTransaction(_transaciton, WITHDRAW_STATUS.PENDING);
        },
        onCancel: () => {
          cancelReserveTransaction(_transaciton, undefined, true);
        },
      });
    };
    try {
      const response = await PostRetryAutoWithdraw({
        token,
        transactionId: _transaciton.transactionId,
        groupBankCode: groupBank.bankCode,
      });
      setLoading({ isLoading: false })
      if (response.service_code === 'BOE-2000' && response.data) {
        cancelReserveTransaction(_transaciton);
        toast.success('บันทึกสำเร็จ');
        const { data } = response;
        NiceModal.show(TransactionResultModal, {
          type: "success",
          title: (
            <>
              โอนเงินอัตโนมัติสำเร็จ
              <br />
              <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
                จากบัญชี PIGSPIN {data.from_bank_code} {data.from_account_name} {data.from_account_number}
              </Typography.Text>
            </>
          ),
          topicMessageBox: "ข้อความแจ้งลูกค้า",
          message: `โอนเงินไปยังลูกค้า ${data.to_bank_code} ${data.to_account_number}\nจำนวน ${response.data.amount_transferred} บาท  เรียบร้อยแล้วค่ะ`,
        })
        doCallWithdrawTransaction(searchCriteria);
      } else {
        setLoading({ isLoading: false })
        cancelReserveTransaction(_transaciton);
        const isAlreadyWithdraw = response.service_code === 'BOE-4002';
        const isCannotAutoWithdraw = response.service_code === 'BOE-4003';
        const isBankNotMatch = response.service_code === 'BOE-4004';
        if (isAlreadyWithdraw) {
          setModal({
            isModalVisible: true,
            type: 'error',
            title: 'ไม่สามารถทำรายการได้',
            content: 'เนื่องจากรายการนี้ถูกดำเนินการแล้ว',
            buttonType: 'confirm',
          })
        } else {
          pushToManualFlow();
        }
      }
      setRetryAutoWithdrawingTransaction(undefined);
    } catch (e: any) {
      cancelReserveTransaction(_transaciton);
    };
    setLoading({ isLoading: false })
    setRetryAutoWithdrawingTransaction(undefined);
  };

  /** WEBSOCKET **/
  const sendMessage = () => {
    /*
    const ws = initialState.websocket
    if (ws.readyState === WebSocket.OPEN) {
      console.log("sendMessage.")
      ws.onopen()
    }
    */
  };
  
  return {
    transactions,
    isTransactionsLoading,
    depositingTransaction,
    withdrawingTransaction,
    refundRequestingTransaction,
    refundingTransaction,
    cancelingRefundTransaction,
    editingManualDeposit,
    deletingManualDeposit,
    editingManualWithdraw,
    deletingManualWithdraw,
    adjustingTransaction,
    getSelectedTab,
    getFilterOptions,
    getHistoryDepositTransactionColumn,
    getBaseTableProps,
    setDepositingTransaction,
    searchValue,
    setSearchValue,
    searchCriteria,
    searchResult,
    setSearchCriteria,
    doCallDepositTransactions,
    doCallWithdrawTransaction,
    reserveTransaction,
    cancelReserveTransaction,
    requestCancelRefundDepositTransaction: requestCancelRefundDepositTransaction,
    setTransactions,
    setSearchResult,
    getAllColumn,
    depositingUser,
    reserveUser,
    cancelReserveUser,
    shouldCallAdjustDeposit,
    setShouldCallAdjustDeposit,
    setRetryAutoWithdrawingTransaction,
    doCallRetryAutoWithdraw,
    showDoneTransactionModal,
    step,
    setStep,
  };
};
