import moment from "moment";
import React from "react";
import { ColumnType } from "src/components";
import { BankRow, BANK_TYPE, GroupBank } from "src/types";
import { BankSelectItem } from "../../components/Bank/BankSelect";
import { mapToBankName } from "../../mapper/mapToBank";
import { useCookies } from "../useCookies";
import { useDepositBankAccounts, useWithdrawBankAccounts } from "../useDepositBankAccount";
import { sortingBank } from "src/utils/bank";
import { BankColumnField, BankColumns } from "src/components/TableColumns/BankColumn";

export type UseBankState = {
  depositBankList: BankRow[];
  withdrawBankList: BankRow[];
  autoWithdrawGroupBank: GroupBank[];
  inActiveDepositBankList: BankRow[];
  inActivewithdrawBankList: BankRow[];
  getWithdrawBankList: () => void;
  getDepositBankList: () => void;
  selectedWithdrawBankAccount?: BankSelectItem;
  setSelectedWithdrawBankAccount: (_b?: BankSelectItem) => void;
  isOpenAddingBank: boolean;
  setIsOpenAddingBank: (_b: boolean) => void;
  bankSelectedTab: BANK_TAB_TYPE;
  setBankSelectedTab: (_b: BANK_TAB_TYPE) => void;
  bankColumns: () => Record<BankColumnField, ColumnType<BankRow>>;
};

export enum BANK_TAB_TYPE {
  DEPOSIT_ALL = 'DEPOSIT_ALL',
  DEPOSIT_BANK = 'DEPOSIT_BANK',
  DEPOSIT_TRUE_MONEY = 'DEPOSIT_TRUE_MONEY',
  WITHDRAW_ALL = 'WITHDRAW_ALL',
  WITHDRAW_BANK = 'WITHDRAW_BANK',
  WITHDRAW_TRUE_MONEY = 'WITHDRAW_TRUE_MONEY',
  INACTIVE_DEPOSIT_ALL = 'INACTIVE_DEPOSIT_ALL',
  INACTIVE_WITHDRAW_ALL = 'INACTIVE_WITHDRAW_ALL',
}

export const useBank = (enableDeposit?:boolean): UseBankState => {
  const [cookies] = useCookies([]);
  const [bankSelectedTab, setBankSelectedTab] = React.useState<BANK_TAB_TYPE>(cookies.BANK_SELECTED_TAB || BANK_TAB_TYPE.DEPOSIT_ALL);

  const [selectedWithdrawBankAccount, setSelectedWithdrawBankAccount] = React.useState<BankSelectItem>();
  const [isOpenAddingBank, setIsOpenAddingBank] = React.useState(false);

  const { data: depositBankList, refetch } = useDepositBankAccounts((data) => {
    return data.map((_bankAccount, index) => {
      const bankCode = _bankAccount.groupBankBankCode;
      const bankRow: BankRow = {
        bankId: _bankAccount.bgbDepositBankCode,
        groupBankId: _bankAccount.groupBankCode,
        groupBankName: _bankAccount.groupBankName,
        bankCode: bankCode,
        key: _bankAccount.bgbDepositBankCode,
        type: BANK_TYPE.DEPOSIT,
        bankName: mapToBankName(bankCode),
        accountName: _bankAccount.bgbDepositBankAccountName,
        accountNumber: _bankAccount.bgbDepositBankAccountNumber,
        promptpay: _bankAccount.bgbDepositBankPromptpayNumber || '',
        isAuto: _bankAccount.bgbDepositBankIsAuto,
        isApi: _bankAccount.isApi || false,
        isSms: _bankAccount.isSms || false,
        status: _bankAccount.bgbDepositBankStatus,
        bankBalance: _bankAccount.latestBalance,
        forcePending: _bankAccount.forcePendingTo ? moment(_bankAccount.forcePendingTo) : undefined,
        verifyAt: new Date(),
      };
      return bankRow;
    }).sort(sortingBank)
  },undefined,enableDeposit
  )

  const { data: withdrawBankList, refetch: refetchWithdrawBankList } = useWithdrawBankAccounts((data) => {
    return data.map((bankAccount) => {
      const bankCode = bankAccount.groupBankBankCode;
      const bankRow: BankRow = {
        bankId: bankAccount.bgbWithdrawBankCode,
        groupBankId: bankAccount.groupBankCode,
        groupBankName: bankAccount.groupBankName,
        key: bankAccount.bgbWithdrawBankCode,
        type: BANK_TYPE.WITHDRAW,
        bankCode: bankAccount.groupBankBankCode,
        bankName: mapToBankName(bankCode),
        accountName: bankAccount.bgbWithdrawBankAccountName,
        accountNumber: bankAccount.bgbWithdrawBankAccountNumber,
        promptpay: '',
        isAuto: bankAccount.bgbWithdrawBankIsAutoBo,
        isWebAuto: bankAccount.bgbWithdrawBankIsAutoWeb,
        status: bankAccount.bgbWithdrawBankStatus,
        bankBalance: bankAccount.bgbWithdrawBankLatestBalance,
        limitAutoPerTransaction: bankAccount.groupBankLimitsTransferAmount,
        forcePending: bankAccount.forcePendingTo ? moment(bankAccount.forcePendingTo) : undefined,
        verifyAt: new Date(),
        autowithdrawDeviceId: bankAccount.autowithdrawDeviceId,
        autowithdrawPin: bankAccount.autowithdrawPin,
        autowithdrawMinimumAmountNoti: bankAccount.autowithdrawMinimumAmountNoti,
      }
      return bankRow;
    }).sort(sortingBank)
  })

  const isActiveBank = (bankRow: BankRow): boolean => {
    return bankRow.status !== "Banned" && bankRow.status !== "Terminated";
  };

  const isInActiveBank = (bankRow: BankRow): boolean => {
    return !isActiveBank(bankRow);
  };

  const activeWithdrawBankList = React.useMemo(() => withdrawBankList?.filter(isActiveBank) || [], [withdrawBankList]);
  const activeWithdrawGroupBanks: GroupBank[] = React.useMemo(() => activeWithdrawBankList?.filter(bank => bank.isAuto)?.reduce<BankRow[]>(
    (previous, current) => {
      if (previous.some(i => i.groupBankId === current.groupBankId)) {
        return previous;
      }
      return [...previous, current];
    }, []).map((bank) => ({
      groupBankId: bank.groupBankId,
      groupBankName: bank.groupBankName,
      bankCode: bank.bankCode,
    })), [activeWithdrawBankList]);

  return {
    depositBankList: depositBankList?.filter(isActiveBank) || [],
    withdrawBankList: activeWithdrawBankList,
    autoWithdrawGroupBank: activeWithdrawGroupBanks,
    inActiveDepositBankList: depositBankList?.filter(isInActiveBank) || [],
    inActivewithdrawBankList: withdrawBankList?.filter(isInActiveBank) || [],
    getWithdrawBankList: refetchWithdrawBankList,
    getDepositBankList: refetch,
    selectedWithdrawBankAccount,
    setSelectedWithdrawBankAccount,
    isOpenAddingBank,
    setIsOpenAddingBank,
    bankSelectedTab,
    setBankSelectedTab,
    bankColumns: BankColumns,
  };
}