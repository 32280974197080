/**
 * Client side
 */
export const PIGSPIN_BO_API_URL = process.env.REACT_APP_PIGSPIN_BO_API_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
export const API_URL = process.env.REACT_APP_PIGSPIN_BO_API_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_PIGSSPIN_BO_WEBSOCKET_URL || "";
export const WEBSOCKET_DEPOSIT_URL = process.env.REACT_APP_PIGSSPIN_BO_WEBSOCKET_DEPOSIT_URL || "";
export const WEBSOCKET_WITHDRAW_URL = process.env.REACT_APP_PIGSSPIN_BO_WEBSOCKET_WITHDRAW_URL || "";

export const MOCK_USER_NAME = process.env.REACT_APP_MOCK_USER_NAME || '';
export const MOCK_USER_TOKEN = process.env.REACT_APP_MOCK_USER_TOKEN || '';
export const MOCK_USER_IMG = process.env.REACT_APP_MOCK_USER_IMG || '';
export const MOCK_USER_ROLE = process.env.REACT_APP_MOCK_USER_ROLE || '';
export const ENABLE_DEV_MODE = process.env.REACT_APP_ENABLE_DEV_MODE === 'true' || false;
export const ENABLE_DEV_MODE_2 = process.env.REACT_APP_ENABLE_DEV_MODE_2 === 'yes' || false;