import { AxiosRequestConfig } from "axios";
import { pigspinBackofficeApiInstance } from "../../utils/api/AxiosInstance";
import { fetchWithJSON } from "../../utils/api/fetch";
import { BaseRequest, BaseResponse2 } from "../service.type";

export type SystemStatusRequest = BaseRequest & {
  product_type: "PIG_BET" | "PIG_SPIN";
};

export type FeatureItem = {
  code: string;
  enabled: boolean;
  message: string;
  name: string;
  uid: string;
  toast: boolean;
  notification: boolean;
  start_time?: string;
  end_time?: string;
  start_datetime?: string;
  end_datetime?: string;
  days_week?: string;
  type_time: string;
  notification_type: string;
};

export type SystemStatusResponse = BaseResponse2<{
  feature_list: FeatureItem[];
}>;

export const GetSystemStatus = async (
  request: SystemStatusRequest
): Promise<SystemStatusResponse> => {
  const requestConfig: AxiosRequestConfig = {
    url: "v1/feature-toggle",
    method: "GET",
    params: {
      product_type: request.product_type || "PIG_SPIN",
    },
    headers: {
      token: request.token,
    },
  };
  const result = await fetchWithJSON<SystemStatusResponse>(
    pigspinBackofficeApiInstance,
    requestConfig
  );
  return result;
};

export type ProductType = "PIG_BET" | "PIG_SPIN";

export type PutSystemStatusRequest = BaseRequest & {
  uid: string;
  code: string;
  name: string;
  enabled: boolean;
  message: string;
  toast: boolean;
  start_time?: string;
  end_time?: string;
  start_datetime?: string;
  end_datetime?: string;
  days_week?: string;
  type_time: string;
  notification: boolean;
  product: ProductType;
  notification_type: string;
};

export type PutSystemStatusResponse = BaseResponse2<{}>;

export const PutSystemStatus = async (
  request: PutSystemStatusRequest
): Promise<PutSystemStatusResponse> => {
  const { token, ...rest } = request;
  console.log(rest)
  const requestConfig: AxiosRequestConfig = {
    url: "v1/feature-toggle",
    method: "PUT",
    headers: {
      token: request.token,
    },
    data: rest,
  };
  const result = await fetchWithJSON<PutSystemStatusResponse>(
    pigspinBackofficeApiInstance,
    requestConfig
  );
  return result;
};
