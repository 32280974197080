import NiceModal from "@ebay/nice-modal-react";
import snakecaseKeys from "snakecase-keys";
import { Form, Space, Col, Row } from "antd";
import isEqual from "react-fast-compare";
import camelcaseKeys from "camelcase-keys";
import * as React from "react";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { AddButton, Button, DepositTransactionModalForm, Icon, Tabs } from "src/components";
import { mapToTransaction } from "src/mapper/mapToTransaction";
import { affiliateStateAtom, authInfoState, modalState } from "src/recoils";
import { ChangeTransactionStatusResponse } from "src/services/changeTransactionStatus/changeTransactionStatus";
import { Scope, BankRow } from "src/types";
import { AlertModal, DepositModal, RefundRequestModal } from "../../components/Modals";
import RadioButton from "../../components/RadioButton/RadioButton";
import Table from "../../components/Table/Table";
import { COOKIES_KEY, useCookies } from "../../hooks/useCookies";
import { DEPOSIT_STATUS, FILTER, SearchCriteria } from "../../hooks/useTransaction/type";
import { useTransactionState } from "../../hooks/useTransaction/useTransaction";
import { getCallConfig } from "../../hooks/useTransaction/config";
import { WEBSOCKET_URL } from '../../models/buildtime-constant';
import { BankSelect } from "../../components/Bank/BankSelect";
import { useBank } from "../../hooks/useTransaction/useBank";
import "./Deposit.scss";


interface IDepositProps { }
let cachingData: any[] = [];

type SearchFrom = {
  adminBank?: BankRow;
}

const websocketURL = WEBSOCKET_URL
let ws = new WebSocket(websocketURL)

export const Deposit: React.FunctionComponent<IDepositProps> = (props) => {
  const [form] = Form.useForm();
  const [, setModal] = useRecoilState(modalState);
  const affiliateState = useRecoilValue(affiliateStateAtom);
  const { token, scope, userId } = useRecoilValue(authInfoState);
  const [searchForm] = Form.useForm<SearchCriteria>();
  const [cookies, setCookies] = useCookies([]);
  const depositState = useTransactionState({
    // filter: cookies.DEPOSIT_SELECTED_TAB || FILTER.PENDING_DEPOSIT,
    filter: FILTER.PENDING_DEPOSIT,
    websocket: ws
  });

  const {
    getSelectedTab,
    getFilterOptions,
    getAllColumn,
    getHistoryDepositTransactionColumn,
    getBaseTableProps,
    cancelingRefundTransaction,
    searchCriteria,
    setSearchCriteria,
    cancelReserveTransaction,
    requestCancelRefundDepositTransaction,
    doCallDepositTransactions,
    setTransactions,
    searchResult,
    setSearchResult,
  } = depositState;

  const {
    depositBankList,
    getDepositBankList
  } = useBank(false);
  
  React.useEffect(() => {
    if (token) { getDepositBankList() }
  }, [token])

  React.useEffect(() => {
    doCallDepositTransactions(searchCriteria);

    if (getSelectedTab() !== FILTER.ONLY_ME_DEPOSIT) {
      ws.onopen = (event) => {
        console.log("[Deposit] Websocket onopen.")
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const { directCall, otherTabCall } = getCallConfig(token, searchCriteria, affiliateState);
        const primaryFilters = {
          ...snakecaseKeys(directCall.payload, { deep: true })
        }
        const secondaryFilters = {
          ...snakecaseKeys(otherTabCall.payload, { deep: true })
        }
        const allFilters = snakecaseKeys({
          primaryFilters,
          secondaryFilters,
          userId
        }, { deep: true })
        ws.send(JSON.stringify(allFilters))
      }
  
      ws.onmessage = function (event) {
        console.log("[Deposit] Websocket onmessage.")
        const json = JSON.parse(event.data)
        const primaryData = json.primary_data_query.data
        const secondaryData = json.secondary_data_query.data
        const primaryDataCache = primaryData.results;
        const shouldPreventExpensiveRender = isEqual(cachingData, primaryDataCache);
        if (shouldPreventExpensiveRender) { return; }
        cachingData = primaryDataCache
        setTransactions(primaryData.results.map((_transaction: any) => mapToTransaction(camelcaseKeys(_transaction, { deep: true }) as any)));
        setSearchResult({
          ...searchResult,
          totalTransaction: primaryData.total,
          totalOtherTransaction: secondaryData.total,
          limit: primaryData.page_size,
          offset: primaryData.page,
          totalPage: primaryData.total / primaryData.page_size,
        })
      }
  
      ws.onclose = () => {
        console.log("Websocket disconnected.")
      }
  
      return () => { ws.close() }
    }
  }, [searchCriteria, affiliateState.isAffiliateMode]);

  const onFinish = (values: SearchFrom) => {
    setSearchCriteria({
      ...searchCriteria,
      adminBankCode: values.adminBank ? values.adminBank.bankCode : undefined,
      adminBankAccountName: values.adminBank ? values.adminBank.accountName : undefined,
    });
  };

  const onFilter = (filter: FILTER) => {
    setSearchCriteria({
      ...searchCriteria,
      offset: 1,
      filter,
      limit: filter === FILTER.PENDING_DEPOSIT ? 200 : 100,
    });
    setCookies(COOKIES_KEY.DEPOSIT_SELECTED_TAB, filter);
  };

  const handleCancelRefundRequest = async () => {
    if (cancelingRefundTransaction) {
      try {
        const response = await requestCancelRefundDepositTransaction(cancelingRefundTransaction);
        if (response?.status === "SUCCESS") {
          toast.success("ยกเลิกแจ้งคืนเงินสำเร็จ");
        } else {
          toast.warning("ยกเลิกแจ้งคืนเงินไม่สำเร็จ");
        }
        closeCancelRefundRequest();
        doCallDepositTransactions(searchCriteria);
      } catch (e: any) {
        const response: ChangeTransactionStatusResponse = e.response.data;
        const isAlreadyRequest = response.service_code === "BOE-4002";
        if (isAlreadyRequest) {
          setModal({
            isModalVisible: true,
            type: "error",
            title: "ไม่สามารถทำรายการได้",
            content: "เนื่องจากรายการนี้ถูกดำเนินการแล้ว",
            buttonType: "confirm",
            onConfirm: () => {
              closeCancelRefundRequest();
              doCallDepositTransactions(searchCriteria);
            },
          });
        } else {
          toast.warning("ยกเลิกแจ้งคืนเงินไม่สำเร็จ");
          closeCancelRefundRequest();
          doCallDepositTransactions(searchCriteria);
        }
      }
    }
  };

  const closeCancelRefundRequest = () => {
    if (cancelingRefundTransaction) {
      cancelReserveTransaction(cancelingRefundTransaction, DEPOSIT_STATUS.CANCEL_REJECT);
    }
  };

  const getPendingDepositTransactionColumn = () => {
    const allColumn = getAllColumn();
    return [
      allColumn.transferedAtDate,
      allColumn.transferedAtTime,
      allColumn.adminAccount,
      { ...allColumn.amount, title: "เติมเงิน" },
      allColumn.customerAccount,
      allColumn.userAccount,
      allColumn.status,
      allColumn.remark,
      allColumn.moreDetail,
      allColumn.cancelReserve,
      allColumn.actions,
    ];
  }

  return (
    <div className="DepositDashboard">
      <Row className="SearchPanel" gutter={[8, 0]}>
        <Col flex="1 1 200px">
          <div>บัญชี PIGSPIN</div>
          <Form
            layout={"inline"}
            style={{ alignItems: "flex-end" }}
            onFinish={onFinish}
          >
            <Form.Item name="adminBank" style={{ width: '300px' }}>
              <BankSelect bankList={depositBankList} />
            </Form.Item>
            <Button size="large" htmlType="submit" type="primary" icon={<Icon name="outline-search-2" />}></Button>
          </Form>
        </Col>
        <Col flex="0 1 160px">
          {
            (scope[Scope.CreateDepositTransaction]?.enable) && (
              <AddButton
                key="add"
                onClick={() =>
                  NiceModal.show(DepositTransactionModalForm, {
                    onSuccess: () => {
                      doCallDepositTransactions(searchCriteria); 
                    },
                  })
                }
              >
                เพิ่มรายการเติม 
              </AddButton>
            )
          }
        </Col>
      </Row>
      <Space>
        <div>
          <Tabs
            defaultActiveKey={getSelectedTab()}
            onChange={(_tab) => {
              onFilter(_tab as FILTER);
            }}
          >
            <Tabs.TabPane tab="รายการที่ต้องทำ" key={FILTER.PENDING_DEPOSIT} />
            {/* 
            TODO : make sure that we dont call all history
            <Tabs.TabPane tab="ประวัติทำรายการ" key={FILTER.ONLY_ME_DEPOSIT} />
             */}
          </Tabs>
          <RadioButton
            value={searchCriteria.filter}
            options={getFilterOptions()}
            onChange={(e) => {
              onFilter(e.target.value);
            }}
          />
        </div>
      </Space>
      <br />
      <br />
      {(getSelectedTab() === FILTER.PENDING_DEPOSIT || getSelectedTab() === FILTER.PENDING_REFUND) && (
        <Table {...getBaseTableProps()} columns={getPendingDepositTransactionColumn()} />
      )}
      {/* 
      TODO : make sure that we dont call all history
      {(getSelectedTab() === FILTER.ONLY_ME_DEPOSIT) && (
        <Table {...getBaseTableProps()} columns={getHistoryDepositTransactionColumn()} />
      )} */}
      <DepositModal depositState={depositState} />
      <RefundRequestModal depositState={depositState} />
      <AlertModal
        isModalVisible={cancelingRefundTransaction !== undefined}
        type="warning"
        buttonType="question"
        title="ยกเลิกแจ้งคืนเงิน"
        content="ต้องการยกเลิกการแจ้งคืนเงินนี้ใช่หรือไม่"
        onCancel={() => closeCancelRefundRequest()}
        onConfirm={() => handleCancelRefundRequest()}
        closeable={false}
        maskClosable={false}
      />
    </div>
  );
};

export default Deposit;
