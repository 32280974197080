import React from 'react';
import { useRecoilValue } from 'recoil';
import { mapToCustomer } from 'src/mapper/mapToCustomer';
import { authState } from 'src/recoils';
import { GetCustomerList, GetCustomerOverview } from 'src/services/customer/customer';
import { CustomerItem } from 'src/services/service.type';
import { CustomerRow } from 'src/types';

export type SearchCustomerResult = {
  list: CustomerRow[];
  totalPage: number;
  totalResultCustomer: number;
  searchingAt: Date;
};

export type CustomerOverview = {
  totalUser: number;
  totalActiveUser: number;
  totalDisabledUser: number;
  totalNewUserToday: number;
}

export type CustomerState = {
  isLoading: boolean;
  customerResult: SearchCustomerResult;
  totalCustomer: number;
  activeCustomer: number;
  inActiveCustomer: number;
  totalResult: number;
  newUser: number;
  editingCustomer?: CustomerRow;
  setEditCustomer: (c?: CustomerRow) => void;
  searchingDate: Date;
  setSearchingDate: (d?: Date) => void;
  searchCriteria: SearchCustomer;
  setSearchCriteria: (param: SearchCustomer) => void;
  textSearch: string;
  setTextSearch: (s: string) => void;
  getCustomerOverview: () => void;
  getCustomerList: (_searchCriteria: SearchCustomer) => void;
  selectedCustomerTab: CUSTOMER_TAB_TYPE;
  setSelectedCustomerTab: (tab: CUSTOMER_TAB_TYPE) => void;
};

export enum CUSTOMER_TAB_TYPE {
  USER_ACCOUNT = 'USER_ACCOUNT',
  CREATED_AT = 'CREATED_AT',
}

export type SearchCustomer = {
  createdAt?: Date;
  phone: string;
  userAccount: string;
  limit: number;
  offset: number;
}

export const useCustomer = (): CustomerState => {
  const { token = '' } = useRecoilValue(authState);
  const [selectedCustomerTab, setSelectedCustomerTab] = React.useState<CUSTOMER_TAB_TYPE>(CUSTOMER_TAB_TYPE.CREATED_AT)
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [customerResult, setCustomerResult] = React.useState<SearchCustomerResult>({
    list: [],
    totalPage: 0,
    totalResultCustomer: 0,
    searchingAt: new Date(),
  });
  const [customerOverview, setCustomerOverview] = React.useState<CustomerOverview>({
    totalUser: 0,
    totalActiveUser: 0,
    totalDisabledUser: 0,
    totalNewUserToday: 0,
  });
  const [editingCustomer, setEditCustomer] = React.useState<CustomerRow>();
  const [textSearch, setTextSearch] = React.useState<string>('');
  const [searchingDate, _setSearchingDate] = React.useState<Date>(new Date());
  const [searchCriteria, setSearchCriteria] = React.useState<SearchCustomer>({
    createdAt: new Date(),
    phone: textSearch,
    userAccount: textSearch,
    limit: 100,
    offset: 0,
  });

  React.useEffect(() => {
    getCustomerList(searchCriteria);
  }, [searchCriteria])

  const getCustomerList = async (_searchCriteria: SearchCustomer) => {
    setIsLoading(true);
    try {
      const response = await GetCustomerList({
        token,
        limit: _searchCriteria.limit,
        offset: _searchCriteria.offset * _searchCriteria.limit,
        createdAt: _searchCriteria.createdAt,
        userAccount: _searchCriteria.userAccount,
        phone: _searchCriteria.phone,
      });
      setCustomerResult({
        ...customerResult,
        list: response.data.results.map(mapToCustomer),
        totalPage: response.data.total_page,
        totalResultCustomer: response.data.total_results_user,
        searchingAt: searchingDate,
      })
    } catch (e: any) {
      setCustomerResult({
        ...customerResult, list: [],
        totalPage: 0,
        totalResultCustomer: 0,
        searchingAt: searchingDate,
      })
    }
    setIsLoading(false);
  }

  const getCustomerOverview = async () => {
    try {
      const response = await GetCustomerOverview({ token });
      if (response.service_code === 'BOE-2000') {
        setCustomerOverview({
          ...customerOverview,
          totalUser: response.data.total_user,
          totalActiveUser: response.data.total_user_active,
          totalDisabledUser: response.data.total_user_disabled,
          totalNewUserToday: response.data.total_user_register,
        })
      } else {
        setCustomerOverview({
          ...customerOverview,
        })
      }
    } catch {
      setCustomerOverview({
        ...customerOverview,
      })
    }
  }

  const setSearchingDate = (_date?: Date) => {
    _setSearchingDate(_date || new Date())
    const isClearDate = !_date;
    if (isClearDate) {
      setSearchCriteria({
        ...searchCriteria,
        userAccount: '',
        phone: '',
        createdAt: new Date(),
      });
    }
  }

  return {
    isLoading,
    totalCustomer: customerOverview.totalUser,
    activeCustomer: customerOverview.totalActiveUser,
    inActiveCustomer: customerOverview.totalDisabledUser,
    newUser: customerOverview.totalNewUserToday,
    totalResult: customerResult.totalResultCustomer,
    customerResult,
    editingCustomer,
    setEditCustomer,
    searchingDate,
    setSearchingDate,
    searchCriteria,
    setSearchCriteria,
    textSearch,
    setTextSearch,
    getCustomerOverview,
    getCustomerList,
    selectedCustomerTab,
    setSelectedCustomerTab,
  };
};
