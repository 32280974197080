import { useQuery } from "react-query"
import { BankCode, BankAccountStatus } from "src/types"
import { api } from "src/utils"

export interface DepositBankAccount {
  bgbDepositBankAccountName: string
  bgbDepositBankAccountNumber: string
  bgbDepositBankCode: string
  bgbDepositBankIsAuto: boolean
  bgbDepositBankNickName: string
  bgbDepositBankPromptpayNumber: string
  bgbDepositBankStatus: BankAccountStatus
  groupBankBankCode: BankCode
  groupBankCode: string
  groupBankName: string
  latestBalance: number;
  forcePendingTo?: Date;
  isApi?: boolean;
  isSms?: boolean;
}


export type WithdrawBankAccount = {
  groupBankCode: string;
  groupBankName: string;
  groupBankBankCode: BankCode;
  groupBankLimitsTransferAmount: number;
  bgbWithdrawBankCode: string;
  bgbWithdrawBankNickName: string;
  bgbWithdrawBankAccountName: string;
  bgbWithdrawBankAccountNumber: string;
  bgbWithdrawBankStatus: BankAccountStatus;
  bgbWithdrawBankIsAutoBo: boolean;
  bgbWithdrawBankIsAutoWeb: boolean;
  bgbWithdrawBankLatestBalance: number;
  forcePendingTo?: Date;
  autowithdrawDeviceId?: string;
  autowithdrawPin?: number;
  autowithdrawMinimumAmountNoti?: number;
}

export function useDepositBankAccounts<T = DepositBankAccount[]>(
  select?: (data: DepositBankAccount[]) => T,
  defaultGroupBankCode?: string,
  enabled: boolean = true,
) {
  return useQuery(
    ["depositBankAccounts", defaultGroupBankCode],
    () => api.get("/payment/deposit/bgb-bank-accounts", { params: { default_groupBank_code: defaultGroupBankCode } }).then((res) => res.data.data),
    { select, enabled }
  )
}

export function useWithdrawBankAccounts<T = WithdrawBankAccount[]>(
  select?: (data: WithdrawBankAccount[]) => T,
  enabled: boolean = true,
) {
  return useQuery(
    ["withdrawBankAccounts"],
    () => api.get("/payment/withdraw/bgb-bank-account", { params: {} }).then((res) => res.data.data),
    { select, enabled }
  )
}
